<template>
  <div class="row">
    <v-col col="12">
      <v-card outlined class="mt-1 mb-0 pb-0">
        <v-card-title class="my-0 py-1">
          <p class="ms-1 my-0 fs-11pt f-raleway fw-700" v-c:P>
            <i class="btn-icon-left" v-i:duo#chalkboard-teacher#18 v-c:P></i>
            Formação & Engajamento do professor
          </p>
          <div class="left mt-0 mb-0 pt-0 ms-4" v-if="equipe != null">
            <avataronline v-for="(user,key,index) in equipe.pessoas" :key="index" :userID="user.id" :size="34"/>
          </div>
        </v-card-title>
      </v-card>
      <v-card v-bg:b flat class="mt-4 p-0">
        <appintegraplugin pluginName="formacao-docente/home" :key="3"/>
      </v-card>
    </v-col>
  </div>
</template>

<script>
// @ is an alias to /src
import { rdb, snapshotValToArray } from '@/firebasedb.js'
import avataronline from "@/components/avatar-online.vue";
import appintegraplugin from "@/components/plugins2/app-integra-plugin.vue";

export default {
  name: 'formacaodocente',
  components: { appintegraplugin, avataronline },
  computed: {
    profileImg: function() {
    }
  },
  data () {
    return {
      tab: null,
      equipe: null,
    }
  },
  mounted() {
    var self = this;
    console.log("formação mounted");
    rdb.ref('/usuarios/equipes/-MfhpwjfiNV6M-NpOtQu').on('value', function(snapshot) {
      self.equipe = snapshot.val();
      console.log("self.equipe",self.equipe);
    });
  },
  methods: {

  }
}
</script>
